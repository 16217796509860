import React from "react";
import { useTable, usePagination } from "react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const SalesCustomerTable = ({
  columns,
  data,
  pageIndex,
  setCurrentPage,
  pageSize,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  totalItems,
  totalPages,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      manualPagination: true,
      pageCount: totalPages
    },
    usePagination
  );
console.log("total items", totalItems);
console.log("total pages here", totalPages, pageCount);
console.log("page index", pageIndex);

  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3; // adjust as needed for display
    const startPage = Math.max(1, pageIndex + 1 - maxPagesToShow);
    const endPage = Math.min(pageCount, pageIndex + 1 + maxPagesToShow);

    if (pageCount > maxPagesToShow) {
      if (startPage > 1) {
        pages.push(
          <button
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
          >
            1
          </button>
        );
        if (startPage > 2) {
          pages.push(
            <span key="start-ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        // console.log("i and pageIndex", i, pageIndex)
        pages.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i-1)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              i === pageIndex + 1 ? "bg-gray-700 text-white" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            }`}
          >
            {i}
          </button>
        );
      }

      if (endPage < pageCount) {
        if (endPage < pageCount - 1) {
          pages.push(
            <span key="end-ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
          );
        }
        pages.push(
          <button
            key={pageCount}
            onClick={() => setCurrentPage(pageCount - 1)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
          >
            {pageCount}
          </button>
        );
      }
    //   }
    // } else {
    //   for (let i = 1; i <= pageCount; i++) {
    //     pages.push(
    //       <button
    //         key={i}
    //         onClick={() => gotoPage(i - 1)}
    //         className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
    //           i === pageIndex + 1 ? "bg-gray-700 text-white" : "text-gray-900 hover:bg-gray-50"
    //         } focus:outline-none`}
    //       >
    //         {i}
    //       </button>
    //     );
    //   }
    }

    return pages;
  };

  return (
    <div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 my-5 border">
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => setCurrentPage(pageIndex-1)}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(pageIndex+1)}
            disabled={!canNextPage}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{pageIndex * pageSize + 1}</span> -{" "}
              <span className="font-medium">
                {Math.min((pageIndex + 1) * pageSize, totalItems)}
              </span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              {/* <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button> */}
              {renderPageNumbers()}
              {/* <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button> */}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCustomerTable;
