import React, { useState, useEffect, useMemo } from 'react';
import Sidebar from '../components/Sidebar';
import SearchBar from '../components/SearchBar';
import MachineStatusTable from '../components/MachineStatus/MachineStatusTable';
import axios from "axios";
import moment from 'moment';
import { HandThumbUpIcon, HandThumbDownIcon, DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { Tooltip } from "react-tooltip";
import CityFilter from '../components/CityFilter';


const MachineStatus = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cityId, setCityId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locations, setLocations] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    fetchLocations();
    fetchData();
  }, []);

  useEffect(() => {
    filterDataByCityAndLocation(cityId, locationId);
  }, [data, cityId, locationId]);

  const calculateMachineHealth = (machine) => {
    const isMachineActive = moment().diff(moment(machine.lastActiveTime), "minutes") < 5;
  
    if (machine.isAllScalesInactive) {
      return { name: "thumbs down", color: "red" };
    } else if (machine.isAnyScaleInactive && isMachineActive) {
      return { name: "thumbs up", color: "yellow" };
    } else if (isMachineActive) {
      return { name: "thumbs up", color: "green" };
    } else {
      return { name: "thumbs down", color: "red" };
    }
  };

  const fetchData = async () => {

    setLoading(true);
    setError(null);
    setFetched(false);

    let endpoint = `${process.env.REACT_APP_MACHINE_SERVICE}/machines/?page=1`;

    try {
      const response = await axios.get(endpoint);
      console.log("machines response", response)
      const fetchedData = response.data.data.machines.rows.map((item) => ({
        ...item,
        machineId: item.machineId,
        machineName: item.displayName,
        lastActiveTime: moment(item.lastActiveTime).format("hh:mm:ss A, Do MMM'YY"),
        health: calculateMachineHealth(item)
      }));
      setData(fetchedData);
      setFilteredData(fetchedData);
      setFetched(true);
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/locations`
      );
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const filterDataByCityAndLocation = (cityId, locationId) => {
    let filtered = data;
    if (cityId) {
      filtered = filtered.filter((item) => {
        const location = locations.find((loc) => loc.id === item.locationId);
        return location && location.cityId === cityId;
      });
    }
    if (locationId) {
      filtered = filtered.filter((item) => item.locationId === locationId);
    }
    setFilteredData(filtered);
  };

  const handleCityChange = (newCityId) => {
    setCityId(newCityId);
    setLocationId(""); // Reset location when city changes
  };

  const handleLocationChange = (newLocationId) => {
    setLocationId(newLocationId);
  };

  const generateColumns = () => {
    let columns = [
      {
        Header: "Machine ID",
        accessor: "machineId",
      },
      {
        Header: "Machine Name",
        accessor: "displayName",
      },
      {
        Header: "Last Active Time",
        accessor: "lastActiveTime",
      },
      {
        Header: "Health",
        accessor: "health",
        Cell: ({ value }) => {
          if (value.name === "thumbs up") {
              return <HandThumbUpIcon className={`h-6 w-6 text-${value.color}-500`} />
          } else {
              return <HandThumbDownIcon className={`h-6 w-6 text-${value.color}-500`} />
          }
      }
      },
    ];

    return columns;
  };

  const columns = useMemo(generateColumns, [locationId]);

  const downloadCSV = () => {
    const timestamp = moment().format("YYYYMMDD_HHmmss");
    const filename = `machine_status_${timestamp}.csv`;
    const csvRows = [];
    const headers = columns.map((col) => col.Header);
    csvRows.push(headers.join(","));

    filteredData.forEach((row) => {
      const values = columns.map((col) => {
        let value = row[col.accessor];
        if (col.accessor === "health") {
            value = value.name === "thumbs up" ? value.color === "yellow" ? "Some Scale Inactive" : "Active" : "Inactive";
        }
        return `"${value.toString().replace(/"/g, '""')}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
};

  console.log("filtered data", filteredData);
  return (
    <div className="flex">
      <Sidebar />
      <div className="lg:pl-72 flex-1">
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto p-5 bg-white rounded-md shadow">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h2 className='text-xl font-bold leading-6 text-gray-900'>Machine Status</h2>
              <div
                    className="relative flex items-center cursor-pointer"
                    onClick={downloadCSV}
                  >
                    <DocumentArrowDownIcon
                      className="ml-4 h-8 w-8 text-blue-500"
                      data-tooltip-id="csv-tooltip"
                      data-tooltip-content="Download CSV"
                    />
                    <Tooltip
                      id="csv-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    />
                  </div>
              </div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pb-2">
                    <div className="inline-block min-w-full py-2 align-middle">
                      <div className="my-4 grid lg:grid-cols-3 items-center">
                        <CityFilter
                          onCityChange={handleCityChange}
                          onLocationChange={handleLocationChange}
                          locations={locations}
                        />
                      {error && (
                        <div
                          className="fixed top-4 right-4 w-80 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg transform transition-transform duration-1000 ease-in-out"
                          role="alert"
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              <strong className="font-bold">
                                Oops! Something went wrong.
                              </strong>
                              <span className="block sm:inline">
                                We encountered an issue while fetching the data.
                                Please try again later.
                              </span>
                            </div>
                            <button
                              className="ml-4"
                              onClick={() => setError(null)}
                            >
                              <svg
                                className="fill-current h-6 w-6 text-red-500"
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <title>Close</title>
                                <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.354 5.652a.5.5 0 10-.707.707l3.647 3.647-3.647 3.646a.5.5 0 00.707.708L10 10.707l3.646 3.646a.5.5 0 00.707-.707l-3.646-3.646 3.646-3.647a.5.5 0 000-.707z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
            </div>
                      { fetched &&
                        !error && (
                          <MachineStatusTable data={filteredData} columns={columns} />
                        )}
          </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </main>
    </div>
    
    </div>
  );
};

export default MachineStatus;
